import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Registration } from "./components/Registration";
import { Catalog } from "./components/Catalog";
import { ItemContainer } from "./components/ItemContainer";
import { Basket } from "./components/Basket";
import { Entrance } from "./components/Entrance";
import { HomeText } from "./components/HomeText";
import { SortCatalog } from "./components/SortCatalog";
import { EditProduct } from "./components/EditProduct";
import { AddProduct } from './components/AddProduct';
import { AdminPanel } from './components/AdminPanel';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "registration",
        element: <Registration />,
      },
      {
        path: "entrance",
        element: <Entrance />,
      },
      {
        path: "adminPanel",
        element: <><AdminPanel /></>
      },
      {
        path: "catalog/addProduct",
        element: <><AddProduct /></>
      },
      {
        path: "catalog/editproduct/:productId?",
        element: (
          <>
           <EditProduct />
          </>
        ),
      },
      {
        path: "catalog/:categoryId?/:subCategoryId?",
        element: (
          <>
            <SortCatalog />
            <Catalog />
          </>
        ),
      },
      {
        path: "basket",
        element: <Basket />,
      },
      {
        path: "home",
        element: (
          <>
            <ItemContainer />
            <HomeText />
          </>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
