import { useQuery } from "react-query";
import { getDataBasket } from "../api";
import { useMutation, useQueryClient } from "react-query";
import { deleteBasket } from "../api";
import { useState } from "react";
import moment from "moment";
import { purchasedProduct } from "../api";
import { useParams } from "react-router-dom";

const ElementBasket = ({ productBasket }) => {
  const queryClient = useQueryClient();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const params = useParams();
  const deleteBasketId = () => deleteBasket(productBasket);
  const productPurchased = (data) => purchasedProduct(productBasket , data);

  const mutation = useMutation(deleteBasketId, {
    onSuccess: () => {
      queryClient.refetchQueries("productBasket");
    },
  });

  const formDataToJson = (formData) => Object.fromEntries(formData);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = formDataToJson(formData);
    console.log(data);
    mutationBasketProduct.mutate(data);
  };

  const mutationBasketProduct = useMutation(productPurchased, {
    onSuccess: () => {
      closeForm();
      queryClient.refetchQueries("productBasket");
    },
  });
  const [isPurchased, setIsPurchased] = useState(false);

  const formatTotal = () => {
    return (productBasket.product.price * productBasket.quantity).toFixed(2);
  };

  return (
    <div className="basket">
      <h3>{productBasket.product.name}</h3>
      <img src={productBasket.product.image} />
      <p>{productBasket.product.price} руб</p>
      <p>{productBasket.size.size} размер</p>
      <p>{productBasket.color.color}</p>
      <div>Всего: {formatTotal()} руб</div>
      <div className={productBasket.purchased ? "purchased" : "not-purchased"}>
        <h3>
          {productBasket.purchased
            ? "Товар на рассмотрении у администратора"
            : "Не оформлен"}
        </h3>
      </div>
      <div className="containerButton">
        {isFormOpen && (
          <div className="purchase-form">
            <h3>Введите информацию о покупке</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  name="address"
                  className="fillingForm"
                  required
                  type="text"
                  placeholder="Адрес доставки"
                />
              </div>
              <div>
                {" "}
                <input
                  name="phone"
                  className="fillingForm"
                  required
                  type="text"
                  placeholder="Номер телефона"
                />
              </div>
              <p>
                <i class="fa-solid fa-star"></i>При подтверждении покупку через
                некоторое время <br /> админ свяжется с вами для подтверждения
                заказа товара
              </p>
              <button className="bay2" onClick={closeForm}>
                Отмена
              </button>
              <button className="bay2">Подтвердить покупку</button>
            </form>
          </div>
        )}
         {!productBasket.purchased && <button onClick={mutation.mutate} className="bay2">
         <i class="fa-solid fa-xmark"></i>
         </button>}
         {!productBasket.purchased &&  <button onClick={openForm} className="bay2">
         Оформить покупку
         </button>}
      </div>
      
    </div>
  );
};

export const Basket = () => {
  const query = useQuery("productBasket", getDataBasket);

  // Вычисляем общую сумму всех товаров в корзине
  const totalSum = query.data
    ? query.data.reduce((acc, productBasket) => {
        return acc + productBasket.product.price * productBasket.quantity;
      }, 0)
    : 0;

  return (
    <div className="basketContainer">
      <div className="title">
        <h1 className="basketText">Корзина</h1>
        <div>Общая сумма: {totalSum.toFixed(2)} руб</div>
      </div>
      {(query.data || []).map((productBasket) => (
        <ElementBasket key={productBasket.id} productBasket={productBasket} />
      ))}
    </div>
  );
};
