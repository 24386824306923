import { getDataCategories } from "../api";
import { useQuery } from "react-query";
import { NavLink , useParams ,useNavigate   } from "react-router-dom";


export const SortCatalog = () => {
  const query = useQuery("categories", getDataCategories);
  return (
    <div>
        <div className="Title">
        <h1>Каталог</h1>
      </div>
    <div className="catalogContainer">
      {(query.data || []).map((category) => (
        <Category  category={category} />
      ))}
    </div>
    </div>
  );
};


const Category = ({ category }) => {
  const navigate = useNavigate();
  const { subCategoryId } = useParams();
  console.log(subCategoryId);

  const handleSubCategoryChange = (subCategoryId) => {
    navigate(`/catalog/${category.id}/${subCategoryId}`); // Перенаправляем пользователя на URL с информацией о выбранной подкатегории
  };

  if (category.subcategories.length === 0) {
    return (
      <div className="sortCatalog">
        <NavLink to={`/catalog/${category.id}`}>
          <span className="Categories">{category.categories}</span>
        </NavLink>
      </div>
    );
  }

  return (
    <div className="sortCatalog">
      <NavLink to={`/catalog/${category.id}`}>
        <span className="Categories">{category.categories}</span>
      </NavLink>
      <select
        className="subCategory"
        value={subCategoryId}
        onChange={(e) => handleSubCategoryChange(e.target.value)}
      >
        {category.subcategories.map((subCategory) => (
          <option key={subCategory.id} value={subCategory.id}>
            <NavLink to={`/catalog/${category.id}/${subCategory.id}`}>
              {subCategory.name}
            </NavLink>
          </option>
        ))}
      </select>
    </div>
  );
};
