

export const ItemContainer = () => {
  return (<div className="item_container">
          <div className="Image_text animate__animated animate__backInRight">
            
          
          </div>
        </div>
  )
}