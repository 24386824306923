import { NavLink } from "react-router-dom";
import { user } from "../api";
import { useQuery } from "react-query";
import { isUserAuth } from "../utils";
import { useState } from "react";

export const Header = () => {
  const query = useQuery("user", user, { enabled: isUserAuth() });
  const { data: userData } = useQuery("user", user);
  const handleLogout = () => {
    // Удаление jwtToken из localStorage
    localStorage.removeItem("jwtToken");
    // Дополнительные действия, например, перенаправление на страницу входа
    // Обновление страницы
    window.location.reload();
  };

  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="header">
      <div className="header_container  wow animate__animated animate__pulse">
        <div className="logo">
          <h1>
            SeV.
            <br />
            .TaT
          </h1>
        </div>
        <div className="Menu" onClick={handleToggleMenu}>
        <div className={`burger-menu ${showMenu ? 'open' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
          <ul className={showMenu ? 'open' : ''}>
            <li>
              <NavLink to="/home">
                {" "}
                <i class="home fa-solid fa-house"></i>
              </NavLink>
            </li>
            <li>
              {" "}
              {userData?.isAdmin && (
                <NavLink to="/adminPanel">
                 <i class="adminPanelUrl fa-solid fa-clipboard"></i>
                </NavLink>
              )}
            </li>
            <li>
              <NavLink to="/catalog">
                {" "}
                <i class="catalogUrl fa-solid fa-store"></i>
              </NavLink>
            </li>
            <li>
              {!userData?.isAdmin &&
                (query.data ? (
                  <NavLink to="/basket">
                    <i class="basket fa-solid fa-basket-shopping"></i>
                  </NavLink>
                ) : null)}
            </li>
            <li>
              {query.data ? (
                query.data.name
              ) : (
                <NavLink to="/entrance">
                  <i className="name fa-solid fa-user" />
                </NavLink>
              )}
            </li>
            <li>
              {query.data ? (
                <button className="goOut" onClick={handleLogout}>
                  Выход
                </button>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
