

export const HomeText = () => {
  
  return (
    <div>
      <div className="indicator-bar">
        Магазин одежды и обуви
      </div>
      <div className="infCatalog">
        <h1 className="infText">НА САЙТЕ ПРИСУТСТВУЕТ</h1>
        <div className="block">
          <h1 className="textM">МУЖСКАЯ ОДЕЖДА</h1>
          <img className="imageM" src="image/mds1.jpg"></img>
          <img className="imageM" src="image/tyf.jpg"></img>
          <h1 className="textG">ЖЕНСКАЯ ОБУВЬ</h1>
        </div>
        <div className="block2">
          <img className="imageM" src="image/6.jpg"></img>
          <h1 className="textM">МУЖСКАЯ ОБУВЬ</h1>
        </div>
      </div>
      <div className="infContainer">
        <div className="inf1">
          <i class="fa-solid fa-flag"></i>
          <p>Пункт выдачи товара : Скидель ул.Луговая 29</p>
        </div>
        <div className="inf2">
          <i class="fa-solid fa-award"></i>
          <p>Нам доверяют магазин работает уже 8 лет</p>
        </div>
        <div className="inf3">
          <i class="fa-solid fa-truck-front"></i>
          <p>Привезём товар которого нет в наличии</p>
        </div>
      </div>
      <h1 className="textEmail">ВЫ МОЖЕТЕ ОТПРАВИТЬ ПИСЬМО НАМ НА ПОЧТУ ЕСЛИ У ВАС ЕСТЬ ВОПРОСЫ</h1>
      <div class="text-field">
        <div class="text-field__group">
          <input
            class="text-field__input"
            type="search"
            id="search"
            name="search"
          ></input>
          <button class="text-field__btn" type="button">
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
};
