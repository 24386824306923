export const Basement = () => {
  return (<div className="basement">
      <div className="info">
        <div className="About_Us">
          <h2>О нас</h2>
          <p>
            Дорогие покупатели! Мы рады приветствовать вас в нашем магазине
            <br />
            одежды и обуви. У нас вы найдете широкий ассортимент товаров для
            <br />
            женщин, мужчин
            <br />
          </p>
          <p>
            Мы понимаем, что каждый покупатель уникален, поэтому у нас есть
            разнообразие
            <br />
            размеров и моделей, чтобы удовлетворить все ваши потребности. Мы
            также предлагаем <br />
            широкий выбор обуви, чтобы дополнить ваш образ.
            <br />
          </p>
          <p />
          <p className="number">Наш телефон : +375333448944</p>
        </div>
        <div className="Return and exchange">
          <h2>Возврат и обмен</h2>
          <p>
            Мы всегда стремимся обеспечить вас качественными и надежными
            <br />
            товарами. Однако, если по каким-то причинам вы не остались
            <br />
            довольны приобретенным у нас товаром, мы предоставляем
            <br />
            возможность его возврата. Для того чтобы вернуть товар,
            <br />
            необходимо ознакомиться с условиями возврата, которые указаны на
            <br />
            сайте или в магазине. Обычно, возврат возможен в течение 14 дней
            <br />
            с момента покупки. Товар должен быть в оригинальной упаковке, не
            <br />
            использованным и не иметь следов эксплуатации.
            <br />
          </p>
        </div>
        <div className="Contact">
          <h2>Соц-сети</h2>
          <ul>
            <li>
              <a href="https://www.instagram.com/shop_skidel_ts?igshid=OGQ5ZDc2ODk2ZA==">
                <i className="fa-brands fa-instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-viber" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-telegram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}